import data from './sayings.csv';

const index = new Map();
const list = new Array();

const createSlug = (saying) => saying
    .split(/\s+/)
    .map(w => w
        .replace(/ö/g, 'oe').replace(/Ö/g, 'Oe')
        .replace(/ä/g, 'ae').replace(/Ä/g, 'Ae')
        .replace(/ü/g, 'ue').replace(/Ü/g, 'Ue')
        .replace(/ß/g, 'ss'))
    .map(w => w.replace(/[^a-zA-Z0-9]/g, ''))
    .map(w => w.toLowerCase())
    .filter(w => w.length > 0)
    .join('_');

for (let [lookup, saying] of data) {
    // skip empty lines
    lookup = lookup.trim();
    saying = saying.trim();
    if (lookup.length == 0 || saying.length == 0) {
        continue;
    }
    // add to list and index
    const slug = createSlug(lookup);
    if (index.has(slug)) {
        console.log(`Error: non unique slug ${slug}`);
        throw new Error('non unique slug');
    }
    console.log(`#${list.length} ${slug}`);
    index.set(slug, {
        position: list.length,
        lookup,
        saying
    });
    list.push(slug);
}

export { index, list };
