import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/fontawesome-pro/css/all.min.css';
import './common.css';
import './404.css';
import * as sayings from '../data/sayings';
import ScrollTopComponent from './scrolltop';

export default () =>
    <div className="notfound page">
        <ScrollTopComponent/>
        <div className="menu-button"><Link to="/"><i className="fal fa-book-open"></i><br /><span>Das Buch</span></Link></div>
        <div className="content">
            <div className="title">404 Not Found</div>
            <div className="divider">⋯</div>
            <div className="proverb">Leider haben wir die Seite nicht gefunden. Schau dir gerne das Buch und die Sprüche an!</div>
            <div className="swipe-icon"></div>
        </div>
        <div className="saying-button"><Link to={ '/' + sayings.list[0] }><i className="fal fa-comment-lines"></i><br /><span>Zu den Spr&uuml;chen</span></Link></div>
    </div>;
