import HomeComponent from './components/home';
import ContactComponent from './components/contact';
import PrivacyComponent from './components/privacy';
import NotFoundComponent from './components/404';
import SayingComponent from './components/saying';

import HomeMeta from './meta/home';
import ContactMeta from './meta/contact';
import PrivacyMeta from './meta/privacy';
import NotFoundMeta from './meta/404';
import SayingMeta from './meta/saying';

import * as sayings from './data/sayings';

export default [
    {
        path: '/',
        exact: true,
        component: HomeComponent,
        meta: HomeMeta,
        valid: () => true,
    },
    {
        path: '/kontakt',
        exact: true,
        component: ContactComponent,
        meta: ContactMeta,
        valid: () => true,
    },
    {
        path: '/datenschutz',
        exact: true,
        component: PrivacyComponent,
        meta: PrivacyMeta,
        valid: () => true,
    },
    {
        path: '/404',
        exact: true,
        component: NotFoundComponent,
        meta: NotFoundMeta,
        valid: () => true,
    },
    {
        path: '/:saying',
        component: SayingComponent,
        meta: SayingMeta,
        valid: ({ params: { saying: slug } }) => sayings.index.has(slug),
    },
];
