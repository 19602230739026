export function shareOnFacebook(url, text) {
    gtag('event', 'share', { method : 'Facebook' });
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url) + (text ? '&quote=' + encodeURIComponent(text) : ''));
}

export function shareOnMessenger(url) {
    gtag('event', 'share', { method : 'Messenger' });
    window.open('fb-messenger://share?link=' + encodeURIComponent(url));
}

export function shareOnWhatsApp(url, text) {
    gtag('event', 'share', { method : 'WhatsApp' });
    window.open('https://wa.me/?text=' + encodeURIComponent((text ? (text + ' ') : '') + url));
}

export function shareOnTelegram(url, text) {
    gtag('event', 'share', { method : 'Telegram' });
    window.open('https://telegram.me/share/url?url=' + encodeURIComponent(url) + (text ? '&text=' + encodeURIComponent(text) : ''));
}
