import * as sayings from '../data/sayings';
import NotFoundMeta from './404';

export default ({ params: { saying: slug } }) => {
    if (!sayings.index.has(slug)) {
        return NotFoundMeta();
    }
    return {
        title: 'Kennst du das… ' + sayings.index.get(slug).saying,
        description: 'Eine Sammlung verborgener Alltagsbegebenheiten',
        image: require('../images/book3.jpg'),
    };
};
