import '@babel/polyfill';
import '../common/logger';
import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import routes from '../common/routes';

hydrate(
    <BrowserRouter>
        {renderRoutes(routes)}
    </BrowserRouter>,
    document.getElementById('app')
);
