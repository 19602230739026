import React from 'react';

export default class ScrollTopComponent extends React.Component {
    componentDidMount() {
        if (window) {
            window.scrollTo(0, 0);
        }
    }
    render() {
        return null;
    }
}
