import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/fontawesome-pro/css/all.min.css';
import * as sayings from '../data/sayings';
import './common.css';
import './saying.css';
import NotFoundComponent from './404';
import { shareOnFacebook, shareOnMessenger, shareOnWhatsApp, shareOnTelegram } from './share-utils';
import { PUBLIC_URL_BASE } from '../globals';
import ScrollTopComponent from './scrolltop';

const COLORS = ['orange', 'cyan', 'white', 'grey'];

export default class SayingComponent extends React.Component {
    constructor(...args) {
        super(...args);

        this.state = {
            shareOpen: false
        };
        this.activeTouch = null;
        this.screenSlug = {
            center: null,
            left: null,
            right: null,
        };

        this.domScreens = null;
        this.refScreens = this.refScreens.bind(this);

        this.eventTouchStart = this.eventTouchStart.bind(this);
        this.eventTouchMove = this.eventTouchMove.bind(this);
        this.eventTouchEnd = this.eventTouchEnd.bind(this);
        this.eventTouchCancel = this.eventTouchCancel.bind(this);
        this.eventClick = this.eventClick.bind(this);
        this.eventKeyDown = this.eventKeyDown.bind(this);
    }

    refScreens(newDomScreens) {
        if (this.domScreens === newDomScreens) {
            return;
        }
        if (this.domScreens) {
            this.domScreens.removeEventListener('touchstart', this.eventTouchStart);
            this.domScreens.removeEventListener('touchmove', this.eventTouchMove);
            this.domScreens.removeEventListener('touchend', this.eventTouchEnd);
            this.domScreens.removeEventListener('touchcancel', this.eventTouchCancel);
            this.domScreens.removeEventListener('click', this.eventClick);
        }
        this.domScreens = newDomScreens;
        if (this.domScreens) {
            this.domScreens.addEventListener('touchstart', this.eventTouchStart, { passive: false });
            this.domScreens.addEventListener('touchmove', this.eventTouchMove, { passive: false });
            this.domScreens.addEventListener('touchend', this.eventTouchEnd, { passive: false });
            this.domScreens.addEventListener('touchcancel', this.eventTouchCancel, { passive: false });
            this.domScreens.addEventListener('click', this.eventClick, { passive: false });
        }
    }

    componentDidMount() {
        if (window) {
            window.addEventListener('keydown', this.eventKeyDown, { passive: false });
        }
    }

    componentWillUnmount() {
        if (window) {
            window.removeEventListener('keydown', this.eventKeyDown);
        }
    }

    actionShare() {
        this.setState((state) => ({
            ...state,
            shareOpen: !state.shareOpen
        }));
    }

    eventKeyDown(event) {
        logger.log(`event ${event.type}`);
        // find target slug
        var targetSlug = null;
        if (event.keyCode == 37) {
            targetSlug = this.screenSlug.left;
        }
        if (event.keyCode == 39) {
            targetSlug = this.screenSlug.right;
        }
        if (!targetSlug) {
            return;
        }
        // this is our event now
        event.preventDefault();
        event.stopPropagation();
        // perform transition
        this.props.history.push('/' + targetSlug);
    }

    eventClick(event) {
        logger.log(`event ${event.type}`);
        // skip if we do not have dom ref
        const domScreens = this.domScreens;
        if (!domScreens) {
            return;
        }
        // skip if event is targeted on buttons or dialog
        if (domScreens.querySelector('.screen.center .menu-button').contains(event.target)
            || domScreens.querySelector('.screen.center .share-button').contains(event.target)
            || domScreens.querySelector('.screen.center .share-dialog').contains(event.target)
        ) {
            return;
        }
        // this is our event now
        event.preventDefault();
        event.stopPropagation();
        // calculate screen width
        const screenWidth = domScreens.offsetWidth / 3;
        // propagate to next page
        if (event.clientX < (screenWidth / 2)) {
            if (this.screenSlug.left) {
                this.props.history.push('/' + this.screenSlug.left);
            }
        }
        else {
            if (this.screenSlug.right) {
                this.props.history.push('/' + this.screenSlug.right);
            }
        }
    }

    eventTouchStart(event) {
        logger.log(`event ${event.type}`);
        // skip if we have active touch point
        if (this.activeTouch) {
            return;
        }
        // skip if we do not have dom ref
        const domScreens = this.domScreens;
        if (!domScreens) {
            return;
        }
        // skip if touch is targeted on buttons or dialog
        const touch = event.changedTouches.item(0);
        if (domScreens.querySelector('.screen.center .menu-button').contains(touch.target)
            || domScreens.querySelector('.screen.center .share-button').contains(touch.target)
            || domScreens.querySelector('.screen.center .share-dialog').contains(touch.target)
        ) {
            return;
        }
        // this is our event now
        event.preventDefault();
        event.stopPropagation();
        // initialize active touch point info
        this.activeTouch = {
            id: touch.identifier,
            startX: touch.clientX,
            lastY: touch.clientY,
        };
    }

    eventTouchMove(event) {
        // verify if we have active touch point
        if (!this.activeTouch) {
            return;
        }
        // identify touch point
        const touch = Array.from(event.changedTouches).find(i => i.identifier == this.activeTouch.id);
        if (!touch) {
            return;
        }
        // this is our event now
        event.preventDefault();
        event.stopPropagation();
        // handle x axis
        let deltaX = touch.clientX - this.activeTouch.startX;
        const domScreens = this.domScreens;
        if (domScreens) {
            // calculate screen width
            const screenWidth = domScreens.offsetWidth / 3;
            // calculate current swipe delta
            deltaX = Math.max(-screenWidth, deltaX);
            deltaX = Math.min(screenWidth, deltaX);
            // adjust user interface
            domScreens.style.transform = `translate3d(${deltaX}px,0,0)`;
        }
        // handle y axis
        const deltaY = touch.clientY - this.activeTouch.lastY;
        this.activeTouch.lastY = touch.clientY;
        if (domScreens) {
            const domCenterScreen = domScreens.querySelector('.screen.center');
            if (domCenterScreen) {
                domCenterScreen.scrollTop -= deltaY;
            }
        }
    }

    eventTouchEnd(event) {
        logger.log(`event ${event.type}`);
        // verify if we have active touch point
        if (!this.activeTouch) {
            return;
        }
        // identify touch point
        const touch = Array.from(event.changedTouches).find(i => i.identifier == this.activeTouch.id);
        if (!touch) {
            return;
        }
        // this is our event now
        event.preventDefault();
        event.stopPropagation();
        // handle x axis
        const deltaX = touch.clientX - this.activeTouch.startX;
        const domScreens = this.domScreens;
        if (domScreens) {
            // calculate screen and barrier width
            const screenWidth = domScreens.offsetWidth / 3;
            const barrierWidth = screenWidth / 5;
            // identify target position and slug
            let targetX = 0;
            let targetSlug = null;
            if (event.type == 'touchend') {
                if (deltaX <= -barrierWidth && this.screenSlug.right) {
                    targetX = -screenWidth;
                    targetSlug = this.screenSlug.right;
                }
                if (deltaX >= barrierWidth && this.screenSlug.left) {
                    targetX = screenWidth;
                    targetSlug = this.screenSlug.left;
                }
            }
            logger.log(`${event.type} targetX=${targetX} targetSlug=${targetSlug}`);
            // begin transition to target
            domScreens.classList.add('transition');
            setTimeout(() => {
                // adjust user interface
                domScreens.style.transform = `translate3d(${targetX}px,0,0)`;
                setTimeout(() => {
                    // end transition to target
                    domScreens.classList.remove('transition');
                    // switch to target slug
                    if (targetSlug) {
                        setTimeout(() => {
                            // update url
                            this.props.history.push('/' + targetSlug);
                            // force rendering and reset temporary adjustments afterwards
                            this.forceUpdate(() => {
                                domScreens.style.transform = `translate3d(0,0,0)`;
                            });
                        }, 0);
                    }
                }, 500);
            }, 0);
        }
        // handle y axis
        const deltaY = touch.clientY - this.activeTouch.lastY;
        this.activeTouch.lastY = touch.clientY;
        if (domScreens) {
            const domCenterScreen = domScreens.querySelector('.screen.center');
            if (domCenterScreen) {
                domCenterScreen.scrollTop -= deltaY;
            }
        }
        // remove active touch point info
        this.activeTouch = null;
    }

    eventTouchCancel(event) {
        this.actionTouchEnd(event);
    }

    render() {
        const { match: { params: { saying: slug } } } = this.props;
        if (!sayings.index.has(slug)) {
            return <NotFoundComponent />;
        }
        const screenSlug = this.screenSlug = {
            center: slug,
            left: sayings.list[(sayings.index.get(slug).position + sayings.list.length - 1) % sayings.list.length],
            right: sayings.list[(sayings.index.get(slug).position + 1) % sayings.list.length],
        };
        const screenColor = (screenName) => COLORS[Math.floor(sayings.index.get(screenSlug[screenName]).position / 4) % COLORS.length];
        const { shareOpen } = this.state;
        const renderScreen = (screenName) =>
            <div key={screenSlug[screenName]} className={`screen ${screenName} ${screenColor(screenName)}`}>
                <div className={'container' + (shareOpen ? ' open-share-dialog' : '')}>
                    <div className="menu-button"><Link to="/"><i className="fal fa-book-open"></i><br /><span>Das Buch</span></Link></div>
                    <div className="content">
                        <div className="title">Kennst du das</div>
                        <div className="divider">⋯</div>
                        <div className="proverb">{sayings.index.get(screenSlug[screenName]).saying}</div>
                    </div>
                    <div className="share-button" onClick={this.actionShare.bind(this)}><i className="fal fa-share-alt fa-rotate-270"></i><i className="fal fa-times"></i><br /><span>Teilen</span></div>
                    <div className="share-dialog">
                        <div className="share-dialog-bar">
                            <div className="share-dialog-button facebook" onClick={() => shareOnFacebook(PUBLIC_URL_BASE + '/' + screenSlug[screenName])}><i className="fab fa-facebook-square"></i><br /><span>Facebook</span></div>
                            <div className="share-dialog-button messenger" onClick={() => shareOnMessenger(PUBLIC_URL_BASE + '/' + screenSlug[screenName])}><i className="fab fa-facebook-messenger"></i><br /><span>Messenger</span></div>
                            <div className="share-dialog-button whatsapp" onClick={() => shareOnWhatsApp(PUBLIC_URL_BASE + '/' + screenSlug[screenName])}><i className="fab fa-whatsapp-square"></i><br /><span>WhatsApp</span></div>
                            <div className="share-dialog-button telegram" onClick={() => shareOnTelegram(PUBLIC_URL_BASE + '/' + screenSlug[screenName])}><i className="fab fa-telegram"></i><br /><span>Telegram</span></div>
                        </div>
                    </div>
                </div>
            </div>;
        return (
            <div className="saying page">
                <ScrollTopComponent />
                <div className="screens" ref={this.refScreens}>
                    {renderScreen('left')}
                    {renderScreen('center')}
                    {renderScreen('right')}
                </div>
                <div className={'swipe-icon ' + screenColor('center')}></div>
            </div>
        );
    }
}
