import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../assets/fontawesome-pro/css/all.min.css';
import './common.css';
import './home.css';
import { shareOnFacebook, shareOnMessenger, shareOnWhatsApp, shareOnTelegram } from './share-utils';
import * as sayings from '../data/sayings';
import { PUBLIC_URL_BASE } from '../globals';
import ScrollTopComponent from './scrolltop';

var SHARE_TEXT = "Mehr als 400 Alltagsbegebenheiten auf über 100 Seiten, ergänzt um 12 Illustrationen, gesammelt über einen Zeitraum von 10 Jahren.";

const carouselSettings = {
    centerMode: true,
    useTransform: true,
    centerPadding: '18%',
    draggable: true,
    slidesToShow: 1,
    infinite: true,
    speed: 600,
    cssEase: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
    arrows: false,
    dots: true,
    initialSlide: 2,
    touchThreshold: 10
};

function openPayPal() {
    gtag('event', 'begin_checkout');
    window.open('https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=Q97YKJMZUL3JQ');
}


export default class HomeComponent extends React.Component {
    constructor(...args) {
        super(...args);
        this.refSlider = React.createRef();
        this.eventKeyDown = this.eventKeyDown.bind(this);
    }

    componentDidMount() {
        if (window) {
            window.addEventListener('keydown', this.eventKeyDown, { passive: false });
        }
    }

    componentWillUnmount() {
        if (window) {
            window.removeEventListener('keydown', this.eventKeyDown);
        }
    }

    eventKeyDown(event) {
        logger.log(`event ${event.type}`);
        // get component
        const comp = this.refSlider.current;
        if(!comp) {
            return;
        }
        // perform slider op
        if (event.keyCode == 37) {
            comp.slickPrev();
        }
        else if (event.keyCode == 39) {
            comp.slickNext();
        }
        else {
            return;
        }
        // this is our event now
        event.preventDefault();
        event.stopPropagation();
    }

    render() {
        return (
            <React.Fragment>
                <ScrollTopComponent />
                <div className="home page">
                    <div className="sayings-button"><Link to={'/' + sayings.list[0]}><i className="fal fa-comment-lines"></i><br /><span>Zu den Spr&uuml;chen</span></Link></div>
                    <div className="content">
                        <div className="title">Das Kennst du das…? Buch</div>
                        <div className="slogan">Eine Sammlung verborgener<br />Alltagsbegebenheiten</div>
                        <div className="carousel">
                            <Slider ref={this.refSlider} className="carousel-content slick-dotted" {...carouselSettings}>
                                <React.Fragment><img src={require('../images/book7_820.jpg')} alt="Buch Foto 7" /><i className="fal fa-search"></i></React.Fragment>
                                <React.Fragment><img src={require('../images/book1_820.jpg')} alt="Buch Foto 1" /><i className="fal fa-search"></i></React.Fragment>
                                <React.Fragment><img src={require('../images/book3_820.jpg')} alt="Buch Foto 3" /><i className="fal fa-search"></i></React.Fragment>
                                <React.Fragment><img src={require('../images/book4_820.jpg')} alt="Buch Foto 4" /><i className="fal fa-search"></i></React.Fragment>
                                <React.Fragment><img src={require('../images/book5_820.jpg')} alt="Buch Foto 5" /><i className="fal fa-search"></i></React.Fragment>
                                <React.Fragment><img src={require('../images/book6_820.jpg')} alt="Buch Foto 6" /><i className="fal fa-search"></i></React.Fragment>
                                <React.Fragment><img src={require('../images/book2_820.jpg')} alt="Buch Foto 2" /><i className="fal fa-search"></i></React.Fragment>
                            </Slider>
                        </div>
                        <div className="buy">
                            <span className="buy-button" onClick={openPayPal}>Buch für 12 € kaufen</span>
                        </div>
                        <div className="about">Mehr als 400 Alltagsbegebenheiten auf über 100 Seiten, ergänzt um 12 Illustrationen, gesammelt über einen Zeitraum von 10 Jahren.</div>
                    </div>
                    <div className="bottom-bar">
                        <div className="share-button facebook" onClick={() => shareOnFacebook(PUBLIC_URL_BASE, SHARE_TEXT)}><i className="fab fa-facebook-square"></i><br /><span>Facebook</span></div>
                        <div className="share-button messenger" onClick={() => shareOnMessenger(PUBLIC_URL_BASE)}><i className="fab fa-facebook-messenger"></i><br /><span>Messenger</span></div>
                        <div className="share-button whatsapp" onClick={() => shareOnWhatsApp(PUBLIC_URL_BASE, SHARE_TEXT)}><i className="fab fa-whatsapp-square"></i><br /><span>WhatsApp</span></div>
                        <div className="share-button telegram" onClick={() => shareOnTelegram(PUBLIC_URL_BASE, SHARE_TEXT)}><i className="fab fa-telegram"></i><br /><span>Telegram</span></div>
                    </div>
                </div>
                <div className="home legals">
                    <div className="legal-item imprint"><Link to="/kontakt">Kontakt</Link></div>
                    <div className="legal-item privacy"><Link to="/datenschutz">Datenschutz</Link></div>
                    <div className="legal-item">Der Preis ist inklusive Versandkosten.<br />Wenden Sie sich per E-Mail direkt an uns, wenn Sie mehrere B&uuml;cher zu Sonderkonditionen bestellen m&ouml;chten.</div>
                </div>
            </React.Fragment>
        );
    }
}
